<template>
  <div>
    <div class="rqltltx">
      <div class="bgmain">
        <div class="title">{{ projectData.project }}脱硫脱销系统</div>
        <div class="row1-main">
          <!-- 左侧部分 -->
          <div class="leftmain fl">
            <div style="margin-top: -1vh; height: 3vh">
              <div class="fl left-lablex">A侧NOX出口浓度</div>
              <div
                class="fl"
                @click="
                  toCompon(
                    2,
                    'NOX_CA_PV',
                    infoList.NOX_CA_PV_node_string,
                    'NOX_CA_PV_BM',
                    'A侧NOX出口浓度'
                  )
                "
              >
                {{'NOX_CA_PV' in infoList?infoList.NOX_CA_PV :0}}&nbsp; mg/m³
              </div>
              <div class="fl left-lablex leftjg" style="margin-left: 1vw">
                B侧NOX出口浓度
              </div>
              <div
                class="fl"
                @click="
                  toCompon(
                    2,
                    'NOX_CB_PV',
                    infoList.NOX_CB_PV_node_string,
                    'NOX_CB_PV_BM',
                    'B侧NOX出口浓度'
                  )
                "
              >
                {{'NOX_CB_PV' in infoList?infoList.NOX_CB_PV :0}}&nbsp; mg/m³
              </div>
            </div>
            <div class="leftrow1">炉膛口</div>
            <div class="leftrow2">
              <div class="fl left-lable" style="color: red">1#氨水分配</div>
              <div class="fl left-lable leftjg" style="color: red">
                2#氨水分配
              </div>
              <div class="fl left-lable leftjg" style="color: red">
                3#氨水分配
              </div>
            </div>
            <!-- AB测烟气流量 -->
            <div class="leftcenter">
              <div class="aycqlil fl">
                <div>
                  <div class="fl leftcenter-lable">A侧烟气流量</div>
                  <div
                    class="fl"
                    @click="
                      toCompon(
                        2,
                        'YQLL_A_PV',
                        infoList.YQLL_A_PV_node_string,
                        'YQLL_A_PV_BM',
                        'A侧烟气流量'
                      )
                    "
                  >
                    {{'YQLL_A_PV' in infoList?infoList.YQLL_A_PV :0}}&nbsp; m³/h
                  </div>
                </div>
                <div>
                  <div class="fl leftcenter-lable">A侧NOX入口浓度</div>
                  <div
                    class="fl"
                    @click="
                      toCompon(
                        2,
                        'NOX_RA_PV',
                        infoList.NOX_RA_PV_node_string,
                        'NOX_RA_PV_BM',
                        'A侧NOX入口浓度'
                      )
                    "
                  >
                    {{'NOX_RA_PV' in infoList?infoList.NOX_RA_PV :0}}&nbsp; mg/m³
                  </div>
                </div>
              </div>
              <div class="aycqlil fl">
                <div>
                  <div class="fl leftcenter-lable">B侧烟气流量</div>
                  <div
                    class="fl"
                    @click="
                      toCompon(
                        2,
                        'YQLL_B_PV',
                        infoList.YQLL_B_PV_node_string,
                        'YQLL_B_PV_BM',
                        'B侧烟气流量'
                      )
                    "
                  >
                    {{'YQLL_B_PV' in infoList?infoList.YQLL_B_PV :0}}&nbsp; m³/h
                  </div>
                </div>
                <div>
                  <div class="fl leftcenter-lable">B侧NOX入口浓度</div>
                  <div
                    class="fl"
                    @click="
                      toCompon(
                        2,
                        'NOX_RB_PV',
                        infoList.NOX_RB_PV_node_string,
                        'NOX_RB_PV_BM',
                        'B侧NOX入口浓度'
                      )
                    "
                  >
                    {{'NOX_RB_PV' in infoList?infoList.NOX_RB_PV :0}}&nbsp; mg/m³
                  </div>
                </div>
              </div>
            </div>

            <div class="aycqlil fl leftcenter-jg">
              <div>
                <div class="fl leftcenter-lable">控制器分钟</div>
                <div
                  class="fl leftcenter-jg1"
                  @click="
                    toCompon(2, 'MINUTE', infoList.MINUTE_node_string, 'MINUTE_BM', '控制器分钟')
                  "
                >
                  {{'MINUTE' in infoList?infoList.MINUTE :0}}&nbsp;m
                </div>
              </div>
              <div>
                <div class="fl leftcenter-lable">控制器秒</div>
                <div
                  class="fl leftcenter-jg1"
                  @click="
                    toCompon(2, 'SECONDS', infoList.SECONDS_node_string, 'SECONDS_BM', '控制器秒')
                  "
                >
                  {{'SECONDS' in infoList?infoList.SECONDS :0}}&nbsp; s
                </div>
              </div>
            </div>

            <div class="leftrow3">压缩空气</div>
            <div class="leftrow4">
              <div class="fl">
                <div
                  class="bottonx fl"
                  :style="{
                    background: !infoList.ECF_QK1.TS
                      ? '#2AFC30'
                      : 'red',
                  }"
                  @click="
                    infoList.ECF_QK1
                      ? toDetail(
                          2,
                          'ECF_QK1',
                          infoList.ECF_QK1_node_string,
                          '',
                          'A侧进口烟气NOX前馈'
                        )
                      : ''
                  "
                >
                  Q
                </div>
                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      infoList.TX_XK1 && !infoList.TX_XK1.RM
                        ? '#2AFC30'
                        : 'red',
                  }"
                  @click="
                    infoList.TX_XK1
                      ? toDetail(
                          1,
                          'TX_XK1',
                          infoList.TX_XK1_node_string,
                          '',
                          'NOX阀门A控制先控调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx fl"
                  style="margin-left: 0.01vw"
                  :style="{
                    background:
                    'TX_MAN1'||'TX_MAN2' in infoList?
                      infoList.TX_MAN1.RM == 1 ||
                      infoList.TX_MAN2.RM == 1
                        ? '#2AFC30'
                        : 'red': 'red',
                  }"
                  @click="toCompon(0, 'MFL_TX_MAN', 2)"
                >
                  A
                </div>
              </div>
              <div class="fl">
                <div class="leftbolable leftrow4jg">NOX SP</div>
                <div
                  style="margin-left: 1vw; height: 2.5vh"
                  @click="
                    toIpt(infoList.TX_A_SP, 'NOX SP', 'TX_A_SP', infoList.TX_A_SP_node_string)
                  "
                >
                  <span class="down">{{'TX_A_SP' in infoList?infoList.TX_A_SP :0}}</span
                  >&nbsp; mg/m³
                </div>

                <div
                  class="leftbolable"
                  style="margin-left: -0.51vw; height: 2.5vh"
                >
                  NOX小时均值SP
                </div>
                <div
                  style="margin-left: 1vw; height: 2.5vh; color: #fff"
                  @click="
                    toCompon(
                      2,
                      'TX_A_SPCL',
                      infoList.TX_A_SPCL_node_string,
                      'TX_A_SPCL_BM',
                      '脱硝左侧实际设定值'
                    )
                  "
                >
                  {{'TX_A_SPCL' in infoList?infoList.TX_A_SPCL :0}}&nbsp;mg/m³
                </div>
              </div>

              <div class="fl leftrow4jg2">
                <div
                  class="bottonx fl"
                  :style="{
                    background: !infoList.ECF_QK2.TS
                      ? '#2AFC30'
                      : 'red',
                  }"
                  @click="
                    infoList.ECF_QK2
                      ? toDetail(
                          2,
                          'ECF_QK2',
                          infoList.ECF_QK2_node_string,
                          '',
                          'B侧进口烟气NOX前馈'
                        )
                      : ''
                  "
                >
                  Q
                </div>
                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      infoList.TX_XK2 && !infoList.TX_XK2.RM
                        ? '#2AFC30'
                        : 'red',
                  }"
                  @click="
                    infoList.TX_XK2
                      ? toDetail(
                          1,
                          'TX_XK2',
                          infoList.TX_XK2_node_string,
                          '',
                          'NOX阀门B控制先控调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx fl"
                  style="margin-left: 0.01vw"
                  :style="{
                    background:
                    'TX_MAN1'||'TX_MAN2' in infoList?
                      infoList.TX_MAN1.RM == 1 ||
                      infoList.TX_MAN2.RM == 1
                        ? '#2AFC30'
                        : 'red': 'red',
                  }"
                  @click="toCompon(0, 'MFL_TX_MAN', 2)"
                >
                  A
                </div>
              </div>
            </div>
            <div class="leftrow5">
              <div class="fl">
                <div class="leftrow5wd">
                  <div class="Kclick" @click="toCompon(4)"></div>

                  <div
                    @click="
                      toCompon(
                        2,
                        'TX_A_FV',
                        infoList.TX_A_FV_node_string,
                        'TX_A_FV_BM',
                        '脱硝A阀门输出反馈'
                      )
                    "
                  >
                    {{'TX_A_FV' in infoList?infoList.TX_A_FV :0}}&nbsp;%
                  </div>
                </div>
                <div class="leftrow5wd">
                  <div
                    @click="
                      toCompon(
                        2,
                        'ATY_A_PV',
                        infoList.ATY_A_PV_node_string,
                        'ATY_A_BM',
                        'A侧烟道氨逃逸量'
                      )
                    "
                  >
                    {{'ATY_A_PV' in infoList?infoList.ATY_A_PV :0}}&nbsp;ppm
                  </div>
                </div>
              </div>
              <div class="fl leftrow5jg">
                <div class="Kclick Kclick1" @click="toCompon(4)"></div>
                <div class="leftrow5wd">
                  <div
                    @click="
                      toCompon(
                        2,
                        'TX_B_FV',
                        infoList.TX_B_FV_node_string,
                        'TX_B_FV_BM',
                        '脱硝B阀门输出反馈'
                      )
                    "
                  >
                    {{'TX_B_FV' in infoList?infoList.TX_B_FV :0}}&nbsp;%
                  </div>
                </div>
                <div class="leftrow5wd">
                  <div
                    @click="
                      toCompon(
                        2,
                        'ATY_B_PV',
                        infoList.ATY_B_PV_node_string,
                        'ATY_B_BM',
                        'B侧烟道氨逃逸流量'
                      )
                    "
                  >
                    {{'ATY_B_PV' in infoList?infoList.ATY_B_PV :0}}&nbsp;ppm
                  </div>
                </div>
              </div>
            </div>
            <div class="leftrow5">
              <div class="fl leftrow5jg3">脱销剂A</div>
              <div class="fl leftrow5jg2">脱销剂B</div>
            </div>
          </div>
          <!-- 中间部分 -->
          <div class="centermain fl">
            <div class="cen-top">
              <div class="fl centerrow1">
                <div class="cen-co1">原烟气监测</div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">SO₂浓度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      class="valuemm"
                      @click="
                        toCompon(
                          2,
                          'YYQ_SO2',
                          infoList.YYQ_SO2_node_string,
                          'YYQ_SO2_BM',
                          '原烟气S02浓度'
                        )
                      "
                    >
                      {{'YYQ_SO2' in infoList?infoList.YYQ_SO2 :0}}&nbsp;mg/m³
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">NOx浓度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'YYQ_NOX',
                          infoList.YYQ_NOX_node_string,
                          'YYQ_NOX_BM',
                          '原烟气NOX浓度'
                        )
                      "
                    >
                      {{'YYQ_NOX' in infoList?infoList.YYQ_NOX :0}}&nbsp;mg/m³
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">O₂浓度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'YYQ_O2',
                          infoList.YYQ_O2_node_string,
                          'YYQ_O2_BM',
                          '原烟气含氧量'
                        )
                      "
                    >
                      {{'YYQ_O2' in infoList?infoList.YYQ_O2 :0}}&nbsp;%
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">DUST浓度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'YYQ_DUST',
                          infoList.YYQ_DUST_node_string,
                          'YYQ_DUST_BM',
                          '原烟气含尘量'
                        )
                      "
                    >
                      {{'YYQ_DUST' in infoList?infoList.YYQ_DUST :0}}&nbsp;mg/m³
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">烟气温度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'YYQ_T',
                          infoList.YYQ_T_node_string,
                          'YYQ_T_BM',
                          '原烟气烟气温度'
                        )
                      "
                    >
                      {{'YYQ_T' in infoList?infoList.YYQ_T :0}}&nbsp;℃
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">烟气流速</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(2, 'YYQ_LS', infoList.YYQ_LS_node_string, 'YYQ_LS_BM', '原烟气流速')
                      "
                    >
                      {{'YYQ_LS' in infoList?infoList.YYQ_LS :0}}&nbsp;m/s
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
              </div>
              <div class="fl centerrow1">
                <div class="cen-co1">净烟气监测</div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">SO₂浓度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'JYQ_SO2',
                          infoList.JYQ_SO2_node_string,
                          'JYQ_SO2_BM',
                          '净烟气SO2浓度'
                        )
                      "
                    >
                      {{'JYQ_SO2' in infoList?infoList.JYQ_SO2 :0}}&nbsp;mg/m³
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">NOx浓度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'JYQ_NOX',
                          infoList.JYQ_NOX_node_string,
                          'JYQ_NOX_BM',
                          '净烟气NOx浓度'
                        )
                      "
                    >
                      {{'JYQ_NOX' in infoList?infoList.JYQ_NOX :0}}&nbsp;mg/m³
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">O₂浓度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'JYQ_O2',
                          infoList.JYQ_O2_node_string,
                          'JYQ_O2_BM',
                          '净烟气含氧量'
                        )
                      "
                    >
                      {{'JYQ_O2' in infoList?infoList.JYQ_O2 :0}}&nbsp;%
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">DUST浓度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'JYQ_DUST',
                          infoList.JYQ_DUST_node_string,
                          'JYQ_DUST_BM',
                          '净烟气含尘量'
                        )
                      "
                    >
                      {{'JYQ_DUST' in infoList?infoList.JYQ_DUST :0}}&nbsp;mg/m³
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">烟气温度</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(
                          2,
                          'JYQ_T',
                          infoList.JYQ_T_node_string,
                          'JYQ_T_BM',
                          '净烟气烟气温度'
                        )
                      "
                    >
                      {{'JYQ_T' in infoList?infoList.JYQ_T :0}}&nbsp;℃
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
                <div class="cen-co2">
                  <div class="cen-co2-row1 fl">烟气流速</div>
                  <div class="cen-co2-row2 fl">
                    <div
                      @click="
                        toCompon(2, 'JYQ_LS', infoList.JYQ_LS_node_string, 'JYQ_LS_BM', '净烟气流速')
                      "
                    >
                      {{'JYQ_LS' in infoList?infoList.JYQ_LS :0}}&nbsp;m/s
                    </div>
                  </div>
                  <div class="leftrow5wd"></div>
                </div>
              </div>
            </div>
            <div class="cen-lable">工艺水</div>
            <div class="cen-lable1">原烟气</div>
            <div class="cen-lable2">
              <div @click="toCompon(2, 'XST_JT', infoList.XST_JT_node_string, 'XST_JT_NG3')">
                {{'XST_JT' in infoList?infoList.XST_JT :0}}&nbsp;℃
              </div>
            </div>
            <div class="cen-lable2 cen-lable2-jg">
              <div
                @click="
                  toCompon(2, 'YYQ_JP', infoList.YYQ_JP_node_string, 'YYQ_JP_BM', '原烟气进口压力')
                "
              >
                {{'YYQ_JP' in infoList?infoList.YYQ_JP :0}}&nbsp;Pa
              </div>
            </div>
            <div class="cen-lable3">循环泵A</div>
            <div class="cen-lable3">循环泵B</div>
            <div class="cen-lable3">循环泵C</div>
            <div class="cen-lable3">循环泵D</div>
          </div>
          <div class="rightmain fl">
            <!-- 脱硫塔炉膛部分 -->
            <div class="rig-left fl">
              <div class="rig-lable1x">脱硫塔</div>
              <div class="rig-lable1 rig-jge">
                <div class="rig-row1 fl">
                  <div
                    @click="
                      toCompon(2, 'XST_T1', infoList.XST_T1_node_string, 'XST_T1_BM', '吸收塔温度')
                    "
                  >
                    {{'XST_T1' in infoList?infoList.XST_T1 :0}}&nbsp;℃
                  </div>
                </div>
                <div class="rig-row1 fl">
                  <div
                    @click="
                      toCompon(2, 'XST_T2', infoList.XST_T2_node_string, 'XST_T2_BM', '吸收塔温度')
                    "
                  >
                    {{'XST_T2' in infoList?infoList.XST_T2 :0}}&nbsp;℃
                  </div>
                </div>
              </div>
              <div class="rig-row1">
                <div
                  @click="
                    toCompon(
                      2,
                      'XST_YW',
                      infoList.XST_YW_node_string,
                      'XST_YW_BM',
                      '吸收塔浆液池液位'
                    )
                  "
                >
                  {{'XST_YW' in infoList?infoList.XST_YW :0}}&nbsp;m
                </div>
              </div>
              <div class="rig-lable1 rig-jge1">
                <div class="rig-row11 fl">DCS液氨理论值</div>
                <div class="rig-row1 fl rig-row1-jg">
                  <div
                    @click="
                      toCompon(
                        2,
                        'LL_NH3',
                        infoList.NH3SP_0_node_string,
                        'NH3SP_0_BM',
                        '理论液氨量'
                      )
                    "
                  >
                    {{'NH3SP_0' in infoList?infoList.NH3SP_0.LL_NH3 :0}}&nbsp;kg/h
                  </div>
                </div>
              </div>
              <div class="rig-lable1" style="margin-left: 0.4vw">
                <div class="rig-row11 fl">DCS液氨SP</div>
                <div class="rig-row1 fl rig-row1-jg">
                  <div
                    @click="
                      toCompon(
                        2,
                        'TL_DSP',
                        infoList.TL_DSP_node_string,
                        'TL_DSP_BM',
                        '液氨流量实际设定值DCS'
                      )
                    "
                  >
                    {{'TL_DSP' in infoList?infoList.TL_DSP :0}}&nbsp;kg/h
                  </div>
                </div>
              </div>
              <div class="rig-lable1" style="margin-left: 0.4vw">
                <div class="rig-row11 fl">液氨SP</div>
                <div class="rig-row1 fl rig-row1-jg down">
                  <div @click="toCompon(2, 'NH3_SP', infoList.NH3_SP_node_string, 'NH3_SP_NG3')">
                    <span class="down">{{'NH3_SP' in infoList?infoList.NH3_SP :0}}</span
                    >&nbsp;kg/h
                  </div>
                </div>
              </div>
              <div class="rig-lable1" style="margin-left: 0.4vw">
                <div class="rig-row11 fl">SO₂ SP</div>
                <div class="rig-row1 fl rig-row1-jg down">
                  <div
                    @click="
                      toCompon(2, 'TL_A_SP', infoList.TL_A_SP_node_string, 'TL_A_SP_BM', 'SO2设定值')
                    "
                  >
                    <span class="down"> {{'TL_A_SP' in infoList?infoList.TL_A_SP :0}}</span
                    >&nbsp;mg/m3
                  </div>
                </div>
              </div>
              <div class="rig-lable1" style="margin-left: 0.4vw">
                <div class="rig-row11 fl" style="margin-left: 0.3vw">
                  SO₂小时均值SP
                </div>
                <div class="rig-row1 fl rig-row1-jg" style="margin-left: 0.3vw">
                  <div
                    @click="
                      toCompon(
                        2,
                        'TL_A_SPCL',
                        infoList.TL_A_SPCL_node_string,
                        'TL_A_SPCL_BM',
                        '脱硫左侧实际设定值'
                      )
                    "
                  >
                    {{'TL_A_SPCL' in infoList?infoList.TL_A_SPCL :0}}&nbsp;mg/m3
                  </div>
                </div>
              </div>
            </div>
            <div class="rig-right fl">
              <div
                style="
                  margin-top: -2vw;
                  height: 2vw;
                  margin-left: -2vw;
                  font-size: 1vw;
                "
              >
                <div class="cen-co2-row1 fl">脱硫效率</div>
                <div class="fl">
                  <div
                    class="valuemm"
                    @click="toCompon(2, 'TL_XL', infoList.TL_XL_node_string, 'TL_XL_BM', '脱硫效率')"
                  >
                    {{'TL_XL' in infoList?infoList.TL_XL :0}}&nbsp;%
                  </div>
                </div>
              </div>
              <div class="rig-lable1">
                <!-- <div  class="rig-rightjg" @click="toCompon(2, 'T_YQ_B', 'MCSOUREC', 'T_YQ_NG3')">{{infoList.MCSOUREC.T_YQ_B}}&nbsp;℃</div> -->
              </div>
              <div class="righ-lable2">
                <div
                  style="
                    margin-top: -6vh;
                    height: 2vh;
                    margin-left: -7vw;
                    font-size: 1vw;
                  "
                >
                  <div class="cen-co2-row1 fl">SO₂小时均值</div>
                  <div class="fl">
                    <div
                      class="valuemm"
                      @click="
                        toCompon(
                          2,
                          'SO2_XSJZ',
                          infoList.SO2_XSJZ_node_string,
                          'SO2_XSJZ_BM',
                          '净烟气SO2小时均值浓度'
                        )
                      "
                    >
                      {{'SO2_XSJZ' in infoList?infoList.SO2_XSJZ :0}}&nbsp;mg/m³
                    </div>
                  </div>
                </div>
                <div style="height: 2vh; margin-left: -7vw; font-size: 1vw">
                  <div class="cen-co2-row1 fl">NOx小时均值</div>
                  <div class="fl valuemm">
                    <div
                      class="valuemm"
                      @click="
                        toCompon(
                          2,
                          'NOX_XSJZ',
                          infoList.NOX_XSJZ_node_string,
                          'NOX_XSJZ_BM',
                          '净烟气NOX小时均值浓度'
                        )
                      "
                    >
                      {{'NOX_XSJZ' in infoList?infoList.NOX_XSJZ :0}}&nbsp;mg/m³
                    </div>
                  </div>
                </div>
                <div class="cwqcss">除雾器冲洗水</div>
              </div>
              <div class="righ-lable3">
                <div class="fl jange">
                  <!-- <div class="mag-top1">石灰石旋流站</div>
                  <div class="mag-top2">事故浆液罐</div>
                  <div class="mag-top3">集水坑</div>
                  <div class="mag-top1">石灰石浆液</div>
                  <div class="mag-top4">氧化空气</div> -->
                </div>
              </div>
              <!-- 最右下角部分 -->
              <div class="rightbottom">
                <div class="rightbottom-jg">液氨</div>
                <div>
                  <div class="fl rightbottom-jg1">
                    <div
                      @click="
                        toCompon(2, 'YALL_PV', infoList.YALL_PV_node_string, 'YALL_PV_BM', '液氨流量')
                      "
                    >
                      {{'YALL_PV' in infoList?infoList.YALL_PV :0}}&nbsp;%
                    </div>
                  </div>
                  <div class="fl rightbottom-jg2">
                    <div
                      class="bottonx fl"
                      :style="{
                        background: !infoList.TL_QK1.TS
                          ? '#2AFC30'
                          : 'red',
                      }"
                      @click="
                        infoList.TL_QK1
                          ? toDetail(
                              2,
                              'TL_QK1',
                              infoList.TL_QK1_node_string,
                              '',
                              '脱硫前馈'
                            )
                          : ''
                      "
                    >
                      Q
                    </div>
                    <div
                      class="bottonx fl"
                      :style="{
                        background: !infoList.TL_QK2.TS
                          ? '#2AFC30'
                          : 'red',
                      }"
                      @click="
                        infoList.TL_QK2
                          ? toDetail(
                              2,
                              'TL_QK2',
                              infoList.TL_QK2_node_string,
                              '',
                              '脱硫前馈'
                            )
                          : ''
                      "
                    >
                      Q
                    </div>
                    <div
                      class="bottonx fl"
                      :style="{
                        background:
                          infoList.TL_XK2 && !infoList.TL_XK2.RM
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.TL_XK2
                          ? toDetail(
                              1,
                              'TL_XK2',
                              infoList.TL_XK2_node_string,
                              '',
                              '脱硫氨液流量控制先控调节'
                            )
                          : ''
                      "
                    >
                      X
                    </div>
                    <div
                      class="bottonx fl"
                      style="margin-left: 0.01vw"
                      :style="{
                        background:
                          infoList.TL_MAN1 &&
                          infoList.TL_MAN1.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }"
                      @click="
                        infoList.TL_MAN1
                          ? toCompon(
                              3,
                              'RM',
                              'TL_MAN1',
                              'TL_MAN1_RM',
                              '脱硫手操器'
                            )
                          : ''
                      "
                    >
                      A
                    </div>
                    <div
                      class="valuemm"
                      @click="
                        toCompon(
                          2,
                          'TL_A_FV',
                          infoList.TL_A_FV_node_string,
                          'TL_A_FV_BM',
                          '脱硫A阀门输出反馈'
                        )
                      "
                    >
                      {{'TL_A_FV' in infoList?infoList.TL_A_FV :0}}&nbsp;%
                    </div>
                  </div>
                  <div class="fl">
                    <div class="rightbottom-jg3">循环槽</div>
                    <div class="rightbottom-jg4">
                      <div
                        @click="
                          toCompon(
                            2,
                            'XHC_YW',
                            infoList.XHC_YW_node_string,
                            'XHC_YW_BM',
                            '循环槽液位'
                          )
                        "
                      >
                        {{'XHC_YW' in infoList?infoList.XHC_YW :0}}&nbsp;m
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mflmanyManual
      v-if="mflmanyManual"
      :titname="Manualtitname"
      :numM="Manualnode"
      :mark="Manualmark"
      :manvar="Manualvar"
      @sendStatus="isClose"
      :infoList="infoList"
    ></mflmanyManual>

    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal"></inputVal>
    <Param01
      v-if="isPshow01"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Param01>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    ></Manual>
  </div>
</template>

	<script>
import mflmanyManual from "@/components/MflmanyManual1.vue"; //多手操器
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Param01 from "@/views/MfBoiler/MFTLTX_C/index.vue";
import Manual from "@/components/Manual1.vue"; //手操器组件
export default {
  name: "index",
  components: {
    mflmanyManual,
    inputVal,
    Param01,
    Manual,
    Historical,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      mflmanyManual: false,
      Manualmark: "",
      Manualvar: "",
      projectData: "",
      isMshow: false,
      isHshow: false,
      isPshow01: false,
      headerStyle: {
        "font-weight": "500",
        height: "1vh",
        borderColor: "#0f3747",
        background: "#205365",
        padding: "0px",
        border: "none",
        color: "#5ca4a6",
      },
      flag: 0,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      Observername: "",
      Observernode: "",
      Rsfname: "",
      Rsfnode: "",
      bodyHeight: "",
      Firstcontrolname: "",
      Firstcontrolnode: "",
      Firstcontroltitname: "",
      fullscreenLoading: true,
      isHshow: false,
      isOshow: false,
      isRshow: false,
      screenWidth: document.body.clientWidth,
      screeHeight: "",
      conHeight: "",
      leftHeight: "",
      leftcon: "",
      tabline: "",
      tabline1: "",
      tabstyle: "",
      tablist: "",
      tabdatawidth: "",
      tabdatawidth1: "",
      Aname: "",
      changValue: null,
      config: {
        data: [10],
        shape: "round",
        colors: ["#43ff44", "#43ff44"],
        waveOpacity: "1",
        waveNum: "50",
        formatter: "{value}",
      },
      rightHeight: "",
      spotArr: [],
      projectData: "",
      grouptime: null,
      authInfo: [],
      UserInfo: [],
      isPshow02: false,
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  computed: {},
  mounted() {},
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }
        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
          case 1:
            pathname = "firstcontol";
            break;
          case 2:
            pathname = "mfkfirstcontol";
            break;
          case 3:
            pathname = "mfRfirstcontol";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    toCompon(key, name, name2, name3, name4, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.nodename = name2;
      this.Aname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];
              // 判断是否为调试还是查看权限
              console.log(str.charAt(str.length - 1));
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无操作权限");
                return;
              }
            } else {
              return (
                (this.mflmanyManual = true),
                (this.Manualtitname = name),
                (this.Manualnode = name2),
                (this.Manualmark = name3),
                (this.Manualvar = titname)
              );
            }
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (this.isHshow = true), (this.chName = name4);
          case 3:
            if (!this.authInfo[1]) {
              let str = this.authInfo[0];
              // 判断是否为调试还是查看权限
              console.log(str.charAt(str.length - 1));
              if (str.charAt(str.length - 1) == "r") {
                this.$message.error("暂无操作权限");
                return;
              }
            } else {
              return (
                (this.isMshow = true),
                (this.Manualname = name),
                (this.Manualnode = name2),
                (this.ManualAname = name3),
                (this.Manualtitname = name4)
              );
            }
          case 4:
            return (this.isPshow01 = true);
        }
      }, 300);
    },
    isClose(val, val1) {
      switch (val) {
        case "Historical":
          return (this.isHshow = val1);
        case "mflmanyManual":
          return (this.mflmanyManual = val1);
        case "Manual":
          return (this.isMshow = val1);
      }
    },
    isClose1() {
      this.isMshow = false;
    },
    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        console.log(str.charAt(str.length - 1));
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无操作权限");
        }
      } else {
        this.$refs.inputVal.open(data, name, historyname, node, type);
      }
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
};
</script>

	<style lang="scss" scoped>
.rqltltx {
  // margin-top:4vh;
  width: 99vw;
  height: 83vh;
  margin-left: 1vw;
  font-size: 0.9vw;
  font-family: PingFang-SC-Regular;
  color: #fff;

  .fl {
    float: left;
  }

  .Kclick {
    width: 1.5vw;
    height: 3.5vh;
    position: absolute;
    z-index: 99;
    top: 0;
    left: 6vw;
  }

  .Kclick1 {
    left: -2vw;
  }

  .bgmain {
    margin-top: 2vh;
    width: 98.2vw;
    height: 83vh;
    background-image: url("~@/assets/images/MFL_tltx.png");
    background-size: 100% 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .title {
    width: 50vw;
    margin-left: 30vw;
    margin-top: 0vh;
    margin-bottom: 14vh;
    height: 4vh;
    font-family: MicrosoftYaHei;
    font-size: 1.6vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 4vh;
    letter-spacing: 0vw;
    color: #ffffff;
    box-shadow: 0vw 0vw 0vw 0vw #009ed2;
  }

  .row1-main {
    width: 98vw;
    height: 78vh;
    // border: #ffffff 1px solid;
    margin-top: -13vh;
  }

  .leftmain {
    width: 28vw;
    cursor: pointer;
    height: 78vh;
    //  margin-top: -3vh;
    // border:1px solid springgreen
  }

  .centermain {
    width: 35vw;
    height: 78vh;
    // border:1px solid springgreen
  }

  .leftcenter {
    margin-top: 14vh;
    // margin-left:1vw;
    height: 2.5vh;
    line-height: 2.5vh;
  }

  .leftcenter-lable {
    width: 8vw;
    color: #5ca4a6;
    text-align: center;
    height: 2.5vh;
  }

  .leftcenter-jg {
    // margin-top:1vh;
    margin-left: 5vw;
  }

  .leftcenter-jg1 {
    margin-left: 1vw;
  }

  .leftrow1 {
    width: 10vw;
    // margin-top:2vh;
    margin-left: 12vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1.2vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0.2vw;
    color: #fefefe;
  }

  .leftrow2 {
    width: 28vw;
    margin-top: 16vh;
    margin-left: 1vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0.2vw;
    color: #fefefe;
  }

  .left-lable {
    width: 6vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #fefefe;
  }

  .left-lablex {
    width: 8vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #5ca4a6;
  }

  .leftjg {
    margin-left: 4vw;
  }

  .leftrow3 {
    width: 28vw;
    margin-top: 12vh;
    margin-left: 23vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0.1vw;
    color: #5ca4a6;
  }

  .bottonx {
    width: 1vw;
    height: 2vh;
    text-align: center;
    line-height: 2vh;
    border: 2px solid rgb(217, 243, 145);
    font-size: 0.9vw;
    margin-right: 0.1vw;
    color: #fff;
    cursor: pointer;
  }

  .leftrow4 {
    margin-top: 8vh;
    width: 28vw;
    margin-left: 5vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0.1vw;
    color: #5ca4a6;
  }

  .down {
    color: #14a4ff;
  }

  .leftrow4jg {
    height: 3vh;
    margin-top: -6vh;
  }

  .leftrow4jg1 {
    margin-left: 3.5vw;
    height: 3vh;
    color: #fff;
  }

  .leftrow4jg2 {
    margin-left: -1vw;
    height: 3vh;
    color: #fff;
  }

  .leftrow5 {
    margin-top: 3vh;
    width: 28vw;
    margin-left: 3vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0.1vw;
    color: #fff;
  }

  .leftrow5wd {
    width: 10vw;
    height: 3vh;
    position: relative;
  }

  .leftrow5jg {
    margin-left: 6vw;
    position: relative;
  }

  .leftrow5jg2 {
    margin-left: 8.8vw;
    margin-top: 2vh;
    color: #5ca4a6;
  }

  .cen-lable2-jg {
    margin-top: -2vh;
  }

  .leftrow5jg3 {
    margin-top: 2vh;
    margin-left: 2vw;
    color: #5ca4a6;
  }

  .leftbolable {
    color: #5ca4a6;
    width: 10vw;
    text-align: center;
    height: 2.5vh;
    font-size: 0.9vw;
  }

  .cen-top {
    width: 35vw;
    height: 25.8vh;
  }

  .cen-lable {
    margin-top: 6vh;
    margin-left: 5vw;
    widows: 1vw;
    height: 7vh;
    font-family: PingFang-SC-Regular;
    font-size: 1.1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #5ca4a6;
  }

  .cen-lable1 {
    margin-top: 4.6vh;
    margin-left: 25vw;
    widows: 1vw;
    height: 7vh;
    font-family: PingFang-SC-Regular;
    font-size: 1.1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #fff;
  }

  .cen-lable2 {
    margin-top: -1.6vh;
    margin-left: 26vw;
    widows: 1vw;
    height: 4.5vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #fff;
  }

  .cen-lable3 {
    margin-top: -0.5vh;
    margin-left: 26vw;
    widows: 1vw;
    height: 7vh;
    font-family: PingFang-SC-Regular;
    font-size: 1.1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #5ca4a6;
  }

  .centerrow1 {
    width: 15.5vw;
    height: 25.8vh;
    border-top: 1px solid #0e3140;
    border-left: 1px solid #0e3140;
    border-right: 1px solid #0e3140;
    margin-left: 1vw;
  }

  .cen-co1 {
    width: 15.5vw;
    height: 5vh;
    background-color: #205365;
    font-size: 1.2vw;
    font-weight: normal;
    font-stretch: normal;
    font-family: MicrosoftYaHei;
    line-height: 5vh;
    letter-spacing: 0vh;
    color: #5ca4a6;
    text-align: center;
  }

  .cen-co2 {
    width: 15.5vw;
    height: 4vh;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
    color: #5ca4a6;
    text-align: center;
    border-bottom: 1px solid #0e3140;
  }

  .cen-co2-row1 {
    width: 6.6vw;
    height: 2vh;
    color: #53d0d3;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    text-align: center;
    font-family: PingFang-SC-Regular;
  }

  .jange {
    margin-left: 12.2vw;
    margin-top: 2.5vh;
    color: #5ca4a6;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    position: relative;
    z-index: 100;
  }

  .mag-top1 {
    margin-top: 1.8vh;
    margin-left: 1vw;
  }

  .mag-top2 {
    margin-top: 1.8vh;
    margin-left: 1vw;
  }

  .mag-top3 {
    margin-top: 1.3vh;
    margin-left: 1vw;
  }

  .mag-top4 {
    margin-top: 3vh;
    margin-left: 1vw;
  }

  .cen-co2-row2 {
    width: 7.6vw;
    height: 4vh;
    color: #fff;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    text-align: center;
    font-family: PingFang-SC-Regular;
    border-left: 1px solid #0e3140;
    cursor: pointer;
  }

  // .rightmain{
  //   font-size: 0.9vw;;
  // }
  .rig-left {
    width: 14vw;
    height: 80vh;
    // border:solid springgreen 1px
  }

  .rig-lable1 {
    width: 15vw;
    height: 3vh;
    font-family: PingFang-SC-Regular;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0vw;
    color: #fefefe;
    text-align: center;
  }

  .rig-lable1x {
    width: 15vw;
    height: 3vh;
    font-family: PingFang-SC-Regular;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0vw;
    color: #fefefe;
    text-align: center;
    font-size: 1.2vw;
  }

  .rig-row1 {
    width: 5.8vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0vw;
    color: #fefefe;
    text-align: center;
    cursor: pointer;
    // margin-left: 1.3vw;
  }

  .rig-row1-jg {
    margin-left: -0.8vw;
    font-size: 0.9vw;
  }

  .rig-row11 {
    width: 7vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 0.9vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0vw;
    color: #fefefe;
    text-align: center;
    margin-left: 1.2vw;
  }

  .rig-jge {
    margin-top: 40vh;
    margin-left: 2.5vw;
  }

  .rig-jge1 {
    margin-top: 5vh;
    margin-left: 0.5vw;
  }

  .rig-jge2 {
    margin-top: 3vh;
  }

  .rig-right {
    width: 20vw;
    height: 80vh;
    // border:solid springgreen 1px
  }

  .rig-rightjg {
    margin-left: 6vw;
  }

  .righ-lable2 {
    margin-top: 0vh;
    margin-left: 15vw;
    width: 10vw;
    height: 2vh;
    font-family: MicrosoftYaHei;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vw;

    // border:1px salmon solid;
  }

  .cwqcss {
    margin-top: 3vh;
    color: #5ca4a6;
  }

  .righ-lable3 {
    margin-top: 30vh;
    margin-left: 2vw;
    width: 21vw;
    height: 20vh;
    font-family: MicrosoftYaHei;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1vw;
    letter-spacing: 0vw;
    // color: #5ca4a6;
    // border:1px salmon solid
  }

  .righ-lable4 {
    margin-top: 2vh;
    margin-left: 2vw;
    width: 22vw;
    height: 20vh;
    font-family: MicrosoftYaHei;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1vw;
    letter-spacing: 0vw;
    // color: #5ca4a6;
    // border:1px salmon solid
  }

  .righ-col1 {
    width: 12vw;
    height: 20vh;
    margin-top: 1.5vh;
    font-size: 1vw;
    margin-left: 1vw;
  }

  .righ-col1-lb {
    width: 5vw;
    height: 2vh;
    margin-left: 0.5vw;
  }

  .righ-col1-jg {
    margin-top: -8vh;
  }

  .righ-col1-jg3 {
    margin-top: -15.5vh;
    margin-left: 1vw;
  }

  .jgxx {
    margin-left: 1vw;
    margin-top: vh;
  }

  .righ-col1-jg2 {
    margin-left: 1vw;
  }

  .righ-col21 {
    width: 10vw;
    height: 20vh;
    margin-top: -10vh;
    font-size: 1vw;
    margin-left: 1vw;
    color: #5ca4a6;
  }

  .jianh {
    margin-top: -4vh;
    margin-left: -1vw;
  }

  .jianh1 {
    margin-top: 14vh;
    margin-left: -1vw;
  }

  .jianh3 {
    margin-left: 13.5vw;
    margin-top: -21vh;
  }

  .jianh4 {
    margin-top: 4vh;
  }

  .rightbottom-jg {
    margin-left: 7vw;
    margin-top: 5vh;
    color: #5ca4a6;
    height: 2vh;
  }

  .rightbottom-jg1 {
    margin-top: 15.2vh;
    margin-left: 1vw;
    cursor: pointer;
  }

  .rightbottom-jg2 {
    margin-top: 17vh;
    margin-left: 1vw;
  }

  .rightbottom-jg3 {
    margin-top: 6vh;
    margin-left: 4vw;
    color: #5ca4a6;
  }

  .rightbottom-jg4 {
    margin-top: 3vh;
    margin-left: 4vw;
    cursor: pointer;
    //  color: #5ca4a6;
  }

  .valuemm {
    cursor: pointer;
  }
}
</style>
