<template>
  <div class="jrlmqzg drag" id="TLTXC" v-draw>
    <!-- 标题部分 -->
    <div>
      <div class="title-hang" fl>{{ projectData.project }}_TLTX_C</div>
      <div class="param11-header-icon" fr @click="closeCompon">
        <img
          src="~@/assets/images/close.png"
          style="width: 100%; height: auto"
        />
      </div>
    </div>
    <div class="titlelable">脱硫脱硝优化控制模型</div>
    <div class="main1">
      <!-- 命名:按钮汉字小写  键值 第一行大写 -->
      <!-- 双行的 -->
      <div class="TL_A_PVC">
        <div class="row">
          <div class="col1 fl">TL_A_PVC</div>
          <div
            class="col2 fl"
            @click="toCompon(2, 'TL_A_PV', infoList.TL_A_PV_node_string, 'TL_A_PV_BM', 'SO2测量值')"
            @dblclick="
              Cclick(infoList.TL_A_PV, 'TL_A_PV', infoList.TL_A_PV_node_string, 'TL_A_PV_BM')
            "
          >
            {{'TL_A_PV' in infoList?infoList.TL_A_PV :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">TL_A_TC</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.TLTX_SO2TC, 'TL_A_TC', 'TLTX_SO2TC', infoList.TLTX_SO2TC_node_string)
            "
          >
            {{'TLTX_SO2TC' in infoList?infoList.TLTX_SO2TC :0}}
          </div>
        </div>
      </div>
      <div class="TL_SP_HL">
        <div class="row">
          <div class="col1 fl">TL_SP_HL</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.TL_MVB_HL, 'TL_SP_HL', 'TL_MVB_HL', infoList.TL_MVB_HL_node_string)
            "
          >
            {{'TL_MVB_HL' in infoList?infoList.TL_MVB_HL :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">TL_SP_LL</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.TL_MVB_LL, 'TL_SP_LL', 'TL_MVB_LL', infoList.TL_MVB_LL_node_string)
            "
          >
            {{'TL_MVB_LL' in infoList?infoList.TL_MVB_LL :0}}
          </div>
        </div>
      </div>
      <div class="YALL_PV">
        <div class="row">
          <div class="col1 fl">YALL_PV</div>
          <div
            class="col2 fl"
            @click="toCompon(2, 'YALL_PV', infoList.YALL_PV_node_string, 'YALL_PV_BM', '液氨流量')"
            @dblclick="
              Cclick(infoList.YALL_PV, 'YALL_PV', infoList.YALL_PV_node_string, 'YALL_PV_BM')
            "
          >
            {{'YALL_PV' in infoList?infoList.YALL_PV :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">YALL_PV_TC</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.TLTX_SO2TC, 'YALL_PV_TC', 'TLTX_SO2TC', infoList.TLTX_SO2TC_node_string)
            "
          >
            {{'TLTX_SO2TC' in infoList?infoList.TLTX_SO2TC :0}}
          </div>
        </div>
      </div>
      <div class="NH3SP_AV">
        <div class="row">
          <div class="col1 fl">NH3SP_AV</div>
          <div
            class="col2 fl"
            @click="
              toCompon(
                2,
                'LL_NH3',
                infoList.NH3SP_0_node_string,
                'NH3SP_0_BM',
                '理论液氨量',
                'NH3SP_0'
              )
            "
            @dblclick="
              Cclick(
                infoList.NH3SP_0.LL_NH3,
                'LL_NH3',
                infoList.NH3SP_0_node_string,
                'NH3SP_0_BM'
              )
            "
          >
            {{'NH3SP_0' in infoList?infoList.NH3SP_0.LL_NH3 :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">NH_XZXS</div>
          <div
            class="col3 fl"
            @click="toIpt(infoList.NH3_XZXS, 'NH_XZXS', 'NH3_XZXS', infoList.NH3_XZXS_node_string)"
          >
            {{'NH3_XZXS' in infoList?infoList.NH3_XZXS :0}}
          </div>
        </div>
      </div>
      <div class="TL_MVA_HL">
        <div class="row">
          <div class="col1 fl">TL_MVA_HL</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.TX_MVB_HL, 'TX_MVB_HL', 'TX_MVB_HL', infoList.TX_MVB_HL_node_string)
            "
          >
            {{'TX_MVB_HL' in infoList?infoList.TX_MVB_HL :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">TL_MVA_LL</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.TX_MVB_LL, 'TL_MVA_LL', 'TL_MVA_LL', infoList.TX_MVB_LL_node_string)
            "
          >
            {{'TX_MVB_LL' in infoList?infoList.TX_MVB_LL :0}}
          </div>
        </div>
      </div>
      <!-- 单行的 -->
      <div class="TL_A_SP">
        <div class="row">
          <div class="col1 fl">TL_A_SP</div>
          <div
            class="col2 fl"
            @click="toCompon(2, 'TL_A_SP', infoList.TL_A_SP_node_string, 'TL_A_SP_BM', 'SO2设定值')"
            @dblclick="
              Cclick(infoList.TL_A_SP, 'TL_A_SP', infoList.TL_A_SP_node_string, 'TL_A_SP_BM')
            "
          >
            {{'TL_A_SP' in infoList?infoList.TL_A_SP :0}}
          </div>
        </div>
      </div>
      <div class="NH3_SP">
        <div class="row">
          <div class="col1 fl">NH3_SP</div>
          <div
            class="col2 fl"
            @click="toCompon(2, 'NH3_SP', infoList.NH3_SP_node_string, 'NH3_SP_BM', '液氨理论设定值')"
            @dblclick="
              Cclick(infoList.NH3_SP, 'NH3_SP', infoList.NH3_SP_node_string, 'NH3_SP_BM')
            "
          >
            {{'NH3_SP' in infoList?infoList.NH3_SP :0}}
          </div>
        </div>
      </div>
      <div class="TL_MAN">
        <div class="row">
          <div class="col1 fl">TL_MAN</div>
          <div
            class="col2 fl"
            @click="
              toCompon(
                2,
                'AV',
                infoList.TX_MAN2_node_string,
                'TX_MAN2_AV_BM',
                '脱硝阀门B手操器',
                'TX_MAN2'
              )
            "
            @dblclick="
              Cclick(
                infoList.TX_MAN2.AV,
                'AV',
                infoList.TX_MAN2_node_string,
                'TX_MAN2_AV_BM'
              )
            "
          >
            {{'TX_MAN2' in infoList?infoList.TX_MAN2.AV :0}}
          </div>
        </div>
      </div>

      <div
        class="bottonx fl x_1"
        :style="{
          background:
            infoList.TL_XK1 == 1 || infoList.TL_XK1 == 2
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList
            ? toDetail(
                1,
                'TL_XK1',
                infoList.TL_XK1_node_string,
                '',
                '脱硫氨液流量控制先控调节'
              )
            : ''
        "
      >
        X
      </div>
      <div
        class="bottonx fl x_2"
        :style="{
          background:
            infoList.TL_XK1 == 1 || infoList.TL_XK1 == 2
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList
            ? toDetail(
                1,
                'TL_XK1',
                infoList.TL_XK1_node_string,
                '',
                '脱硫氨液流量控制先控调节'
              )
            : ''
        "
      >
        X
      </div>
      <div
        class="bottonx fl a_1"
        :style="{
          background: infoList.CYQ_XK13 == 1 || infoList.CYQ_XK13 == 2 ? '#2AFC30' : 'red',
        }"
        @click="
          infoList
            ? toDetail(1, 'XK13', infoList.CYQ_XK13_node_string, '', '煤气总管压力控制XK')
            : ''
        "
      >
        A
      </div>
      <div
        class="bottonx fl q_1"
        :style="{
          background:
            infoList.TL_QK1 == 1 || infoList.TL_QK1 == 2
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList ? toDetail(1, 'TL_QK1', infoList.TL_QK1_node_string, '', '前馈') : ''
        "
      >
        Q
      </div>
      <div
        class="bottonx fl q_2"
        :style="{
          background:
            infoList.TL_QK2 == 1 || infoList.TL_QK2 == 2
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList ? toDetail(1, 'TL_QK2', infoList.TL_QK2_node_string, '', '前馈') : ''
        "
      >
        Q
      </div>

      <!-- 背景图div -->
    </div>
    <!-- 左下模型 -->
    <div class="main2">
      <div class="TX_A_PVL">
        <div class="row">
          <div class="col1 fl">TX_A_PVL</div>
          <div
            class="col3 fl"
            @click="toCompon(2, 'TX_A_PV', infoList.TX_A_PV_node_string, 'TX_A_PV_BM', 'NOX测量值')"
            @dblclick="
              Cclick(infoList.TX_A_PV, 'TX_A_PV', infoList.TX_A_PV_node_string, 'TX_A_PV_BM')
            "
          >
            {{'TX_A_PV' in infoList?infoList.TX_A_PV :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">TX_A_TC</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.TLTX_NOXTC, 'TX_A_TC', 'TLTX_NOXTC', infoList.TLTX_NOXTC_node_string)
            "
          >
            {{'TLTX_NOXTC' in infoList?infoList.TLTX_NOXTC :0}}
          </div>
        </div>
      </div>
      <div class="TX_A_SP">
        <div class="row">
          <div class="col1 fl">TX_A_SP</div>
          <div
            class="col2 fl"
            @click="toCompon(2, 'TX_A_SP', infoList.TX_A_SP_node_string, 'TX_A_SP_BM', 'NOX设定值')"
            @dblclick="
              Cclick(infoList.TX_A_SP, 'TX_A_SP', infoList.TX_A_SP_node_string, 'TX_A_SP_BM')
            "
          >
            {{'TX_A_SP' in infoList?infoList.TX_A_SP :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">TX_SP_HL</div>
          <div
            class="col3 fl"
            @click="toIpt(infoList.TX_SP_HL, 'TX_SP_HL', 'TX_SP_HL', infoList.TX_SP_HL_node_string)"
          >
            {{'TX_SP_HL' in infoList?infoList.TX_SP_HL :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">TX_SP_LL</div>
          <div
            class="col3 fl"
            @click="toIpt(infoList.TX_SP_LL, 'TX_SP_LL', 'TX_SP_LL', infoList.TX_SP_LL_node_string)"
          >
            {{'TX_SP_LL' in infoList?infoList.TX_SP_LL :0}}
          </div>
        </div>
      </div>
      <div class="TX_MAN">
        <div class="row">
          <div class="col1 fl">TX_MAN</div>
          <div
            class="col2 fl"
            @click="
              toCompon(
                2,
                'AV',
                infoList.TX_MAN1_node_string,
                'TX_MAN1_AV_BM',
                '脱硝阀门A手操器',
                'TX_MAN1'
              )
            "
            @dblclick="
              Cclick(
                infoList.TX_MAN1.AV,
                'AV',
                infoList.TX_MAN1_node_string,
                'TX_MAN1_AV_BM'
              )
            "
          >
            {{'TX_MAN1' in infoList?infoList.TX_MAN1.AV :0}}
          </div>
        </div>
      </div>
      <div class="TX_MAN_HL">
        <div class="row">
          <div class="col1 fl">TX_MAN_HL</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.TX_MVA_HL, 'TX_MVA_HL', 'TX_MVA_HL', infoList.TX_MVA_HL_node_string)
            "
          >
            {{'TX_MVA_HL' in infoList?infoList.TX_MVA_HL :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">TX_MAN_LL</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.TX_MVA_LL, 'TX_MAN_LL', 'TX_MVA_LL', infoList.TX_MVA_LL_node_string)
            "
          >
            {{'TX_MVA_LL' in infoList?infoList.TX_MVA_LL :0}}
          </div>
        </div>
      </div>
      <div
        class="bottonx fl a_1"
        :style="{
          background: infoList.CYQ_XK13 == 1 || infoList.CYQ_XK13 == 2 ? '#2AFC30' : 'red',
        }"
        @click="
          infoList
            ? toDetail(1, 'XK13', infoList.CYQ_XK13_node_string, '', '煤气总管压力控制XK')
            : ''
        "
      >
        A
      </div>
      <div
        class="bottonx fl x_1"
        :style="{
          background:
            infoList.TX_XK1 == 1 || infoList.TX_XK1 == 2
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList
            ? toDetail(1, 'TX_XK1', infoList.TX_XK1_node_string, '', 'NOX阀门A控制先控调节')
            : ''
        "
      >
        X
      </div>
      <div
        class="bottonx fl q_1"
        :style="{
          background:
            infoList.ECF_QK1 == 1 || infoList.ECF_QK1 == 2
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList
            ? toDetail(
                1,
                'ECF_QK1',
                infoList.ECF_QK1_node_string,
                '',
                'A侧进口烟气NOX前馈'
              )
            : ''
        "
      >
        Q
      </div>
    </div>
    <!-- 右下模型 -->
    <div class="main3">
      <div class="TX_A_PVL">
        <div class="row">
          <div class="col1 fl">TX_A_PVL</div>
          <div
            class="col3 fl"
            @click="toCompon(2, 'TX_A_PV', infoList.TX_A_PV_node_string, 'TX_A_PV_BM', 'NOX测量值')"
            @dblclick="
              Cclick(infoList.TX_A_PV, 'TX_A_PV', infoList.TX_A_PV_node_string, 'TX_A_PV_BM')
            "
          >
            {{'TX_A_PV' in infoList?infoList.TX_A_PV :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">TX_A_TC</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.TLTX_NOXTC, 'TX_A_TC', 'TLTX_NOXTC', infoList.TLTX_NOXTC_node_string)
            "
          >
            {{'TLTX_NOXTC' in infoList?infoList.TLTX_NOXTC :0}}
          </div>
        </div>
      </div>
      <div class="TX_A_SP">
        <div class="row">
          <div class="col1 fl">TX_A_SP</div>
          <div
            class="col2 fl"
            @click="toCompon(2, 'TX_A_SP', infoList.TX_A_SP_node_string, 'TX_A_SP_BM', 'NOX设定值')"
            @dblclick="
              Cclick(infoList.TX_A_SP, 'TX_A_SP', infoList.TX_A_SP_node_string, 'TX_A_SP_BM')
            "
          >
            {{'TX_A_SP' in infoList?infoList.TX_A_SP :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">TX_SP_HL</div>
          <div
            class="col3 fl"
            @click="toIpt(infoList.TX_SP_HL, 'TX_SP_HL', 'TX_SP_HL', infoList.TX_SP_HL_node_string)"
          >
            {{'TX_SP_HL' in infoList?infoList.TX_SP_HL :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">TX_SP_LL</div>
          <div
            class="col3 fl"
            @click="toIpt(infoList.TX_SP_LL, 'TX_SP_LL', 'TX_SP_LL', infoList.TX_SP_LL_node_string)"
          >
            {{'TX_SP_LL' in infoList?infoList.TX_SP_LL :0}}
          </div>
        </div>
      </div>
      <div class="TX_MAN">
        <div class="row">
          <div class="col1 fl">TX_MAN</div>
          <div
            class="col2 fl"
            @click="
              toCompon(
                2,
                'AV',
                infoList.TX_MAN2_node_string,
                'TX_MAN2_AV_BM',
                '脱硝阀门B手操器',
                'TX_MAN2'
              )
            "
            @dblclick="
              Cclick(
                infoList.TX_MAN2.AV,
                'AV',
                infoList.TX_MAN2_node_string,
                'TX_MAN2_AV_BM'
              )
            "
          >
            {{'TX_MAN2' in infoList?infoList.TX_MAN2.AV :0}}
          </div>
        </div>
      </div>
      <div class="TX_MAN_HL">
        <div class="row">
          <div class="col1 fl">TX_MAN_HL</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.TX_MVB_HL, 'TX_MVB_HL', 'TX_MVB_HL', infoList.TX_MVB_HL_node_string)
            "
          >
            {{'TX_MVB_HL' in infoList?infoList.TX_MVB_HL :0}}
          </div>
        </div>
        <div class="row">
          <div class="col1 fl">TX_MAN_LL</div>
          <div
            class="col3 fl"
            @click="
              toIpt(infoList.TX_MVB_LL, 'TX_MAN_LL', 'TX_MVB_LL', infoList.TX_MVB_LL_node_string)
            "
          >
            {{'TX_MVB_LL' in infoList?infoList.TX_MVB_LL :0}}
          </div>
        </div>
      </div>
      <div
        class="bottonx fl a_1"
        :style="{
          background: infoList.CYQ_XK13 == 1 || infoList.CYQ_XK13 == 2 ? '#2AFC30' : 'red',
        }"
        @click="
          infoList
            ? toDetail(1, 'XK13', infoList.CYQ_XK13_node_string, '', '煤气总管压力控制XK')
            : ''
        "
      >
        A
      </div>
      <div
        class="bottonx fl x_1"
        :style="{
          background:
            infoList.TX_XK2 == 1 || infoList.TX_XK2 == 2
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList
            ? toDetail(
                1,
                'TX_XK2',
                infoList.TX_XK2_node_string,
                '',
                '脱硫氨液流量控制先控调节'
              )
            : ''
        "
      >
        X
      </div>
      <div
        class="bottonx fl q_1"
        :style="{
          background:
            infoList.ECF_QK2 == 1 || infoList.ECF_QK2 == 2
              ? '#2AFC30'
              : 'red',
        }"
        @click="
          infoList
            ? toDetail(
                1,
                'ECF_QK2',
                infoList.ECF_QK2_node_string,
                '',
                'B侧进口烟气NOX前馈'
              )
            : ''
        "
      >
        Q
      </div>
    </div>

    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    ></Manual>

     <Historical
      v-if="isHshow"
      @sendStatus="isClose1"
      :historyname="historyname"
      :node="node"
      :node1="node1"
      :Lkname="Lkname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal"></inputVal>
  </div>
</template>


<script>
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Rsf from "@/components/RSF.vue"; //阮伺服组件
import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
import Manual from "@/components/Manual1.vue"; //手操器组件
export default {
  name: "Param11",
  props: {
    infoList: {
      type: Object,
      default: {}, // 默认值
    },
  },
  components: { inputVal, Historical, Firstcontrol, Rsf, ParameterYh, Manual },
  data: () => {
    return {
      chName: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      isHshow: false,
      isMshow: false,
      isRshow: false,
      historyname: "",
      node: "",
      Lkname: "",
      isIndex: "",
      Firstcontrolname: "",
      Firstcontroltitname: "",
      Firstcontrolnode: "",
      isFshow: false,
      Rsfname: "",
      Rsfnode: "",
      Rsftitname: "",
      projectData: "",
      grouptime: null,
      spotArr: [],
      authInfo: [],
      UserInfo: [],
      node1:''
    };
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }
        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
          case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "mfkfirstcontol";
            break;
          case 3:
            pathname = "mfRfirstcontol";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
   
    closeCompon() {
      this.$emit("sendStatus", "isPshow01", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "Historical":
          return (this.isHshow = val1);
      }
    },
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    toCompon(key, name, name2, name3, name4, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            return (
              (this.isMshow = true),
              (this.Manualname = name),
              (this.Manualnode = name2),
              (this.ManualAname = name3),
              (this.Manualtitname = name4)
            );
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (this.isHshow = true), (this.chName = name4),this.node1=titname;
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = name2),
              (this.Rsftitname = titname)
            );
        }
      }, 300);
    },
  },
};
</script>


<style lang="scss" scoped>
.jrlmqzg {
  width: 53vw;
  height: 78vh;
  //  background: #e9f7ff;
  background-image: url("~@/assets/images/jrl-param.png");
  background-size: 100% 100%;
  //  cursor:move;
  border-radius: 5px;
  color: #ffffff;

  .zhuangshi1 {
    width: 30vw;
    height: 10vh;
    background-color: #0ef7ff;
    opacity: 0.66;
  }
  // 上半模型
  .main1 {
    width: 34.2vw;
    height: 16.7vh;
    //  background: #e9f7ff;
    background-image: url("~@/assets/images/MFL_脱硫脱硝切图01.png");
    background-size: 100% 100%;
    //  cursor:move;
    border-radius: 5px;
    color: #ffffff;
    position: absolute;
    left: 8vw;
    top: 17vh;
  }
  .TL_A_PVC {
    width: 7.3vw;
    height: 5.2vh;
    background-color: #001b2860;
    border-radius: 0vh;
    border: solid 0vh #236f8d;
    position: absolute;
    left: -7.2vw;
    top: -2.9vh;
    .row {
      width: 7.3vw;
      height: 2.6vh;
      .col1 {
        width: 4vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #8aeaff;
        text-align: center;
      }
      .col2 {
        width: 3.3vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #2fc3e3;
        text-align: center;
      }
      .col3 {
        width: 3.3vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #00ffb4;
        text-align: center;
      }
    }
  }
  .TL_A_SP {
    width: 7.3vw;
    height: 2.6vh;
    background-color: #001b2860;
    border-radius: 0vh;
    border: solid 0vh #236f8d;
    position: absolute;
    left: -7.2vw;
    top: 3.2vh;
    .row {
      width: 7.3vw;
      height: 2.6vh;
      .col1 {
        width: 4vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #8aeaff;
        text-align: center;
      }
      .col2 {
        width: 3.3vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #2fc3e3;
        text-align: center;
      }
    }
  }
  .NH3SP_AV {
    width: 8.3vw;
    height: 5.2vh;
    background-color: #001b2860;
    border-radius: 0vh;
    border: solid 0vh #236f8d;
    position: absolute;
    left: -7.3vw;
    top: 11vh;
    .row {
      width: 8.3vw;
      height: 2.6vh;
      .col1 {
        width: 4.5vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #8aeaff;
        text-align: center;
      }
      .col2 {
        width: 3.8vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #2fc3e3;
        text-align: center;
      }
      .col3 {
        width: 3.8vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #00ffb4;
        text-align: center;
      }
    }
  }
  .TL_SP_HL {
    width: 7.3vw;
    height: 5.2vh;
    background-color: #001b2860;
    border-radius: 0vh;
    border: solid 0vh #236f8d;
    position: absolute;
    left: 6.5vw;
    top: -3.9vh;
    .row {
      width: 7.3vw;
      height: 2.6vh;
      .col1 {
        width: 4vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #8aeaff;
        text-align: center;
      }
      .col2 {
        width: 3.3vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #2fc3e3;
        text-align: center;
      }
      .col3 {
        width: 3.3vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #00ffb4;
        text-align: center;
      }
    }
  }
  .YALL_PV {
    width: 8.3vw;
    height: 5.2vh;
    background-color: #001b2860;
    border-radius: 0vh;
    border: solid 0vh #236f8d;
    position: absolute;
    left: 13.6vw;
    top: 11vh;
    .row {
      width: 8.3vw;
      height: 2.6vh;
      .col1 {
        width: 4.5vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #8aeaff;
        text-align: center;
      }
      .col2 {
        width: 3.8vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #2fc3e3;
        text-align: center;
      }
      .col3 {
        width: 3.8vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #00ffb4;
        text-align: center;
      }
    }
  }
  .TL_MVA_HL {
    width: 8.3vw;
    height: 5.2vh;
    background-color: #001b2860;
    border-radius: 0vh;
    border: solid 0vh #236f8d;
    position: absolute;
    left: 35.8vw;
    top: 1.8vh;
    .row {
      width: 8.3vw;
      height: 2.6vh;
      .col1 {
        width: 4.5vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #8aeaff;
        text-align: center;
      }
      .col2 {
        width: 3.8vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #2fc3e3;
        text-align: center;
      }
      .col3 {
        width: 3.8vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #00ffb4;
        text-align: center;
      }
    }
  }
  .NH3_SP {
    width: 7.3vw;
    height: 2.6vh;
    background-color: #001b2860;
    border-radius: 0vh;
    border: solid 0vh #236f8d;
    position: absolute;
    left: 14vw;
    top: 1vh;
    .row {
      width: 7.3vw;
      height: 2.6vh;
      .col1 {
        width: 4vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #8aeaff;
        text-align: center;
      }
      .col2 {
        width: 3.3vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #2fc3e3;
        text-align: center;
      }
    }
  }
  .TL_MAN {
    width: 7.3vw;
    height: 2.6vh;
    background-color: #001b2860;
    border-radius: 0vh;
    border: solid 0vh #236f8d;
    position: absolute;
    left: 35.9vw;
    top: -3vh;
    .row {
      width: 7.3vw;
      height: 2.6vh;
      .col1 {
        width: 4vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #8aeaff;
        text-align: center;
      }
      .col2 {
        width: 3.3vw;
        font-family: PingFang-SC-Regular;
        font-size: 0.9vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 2.6vh;
        letter-spacing: 0vh;
        color: #2fc3e3;
        text-align: center;
      }
    }
  }
  .x_1 {
    position: absolute;
    left: 4.7vw;
    top: 3.4vh;
  }
  .x_2 {
    position: absolute;
    left: 24vw;
    top: 3.4vh;
  }
  .a_1 {
    position: absolute;
    left: 34.2vw;
    top: 3.4vh;
  }
  .q_2 {
    position: absolute;
    left: 29.9vw;
    top: 14.5vh;
  }
  .q_1 {
    position: absolute;
    left: 26.5vw;
    top: 14.5vh;
  }
  // 下左模型
  .main2 {
    width: 9vw;
    height: 14vh;
    //  background: #e9f7ff;
    background-image: url("~@/assets/images/MFL_脱硫脱硝切图02.png");
    background-size: 100% 100%;
    //  cursor:move;
    border-radius: 5px;
    color: #ffffff;
    position: absolute;
    left: 8vw;
    top: 50vh;
    .TX_A_PVL {
      width: 7.3vw;
      height: 5.2vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: -7.2vw;
      top: -2.6vh;
      .row {
        width: 7.3vw;
        height: 2.6vh;
        .col1 {
          width: 4vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;
        }
      }
    }
    .TX_A_SP {
      width: 7.3vw;
      height: 8vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: -7.2vw;
      top: 3.4vh;
      .row {
        width: 7.3vw;
        height: 2.6vh;
        .col1 {
          width: 4vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;
        }
      }
    }
    .TX_MAN {
      width: 7.3vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 10.5vw;
      top: -2vh;
      .row {
        width: 7.3vw;
        height: 2.6vh;
        .col1 {
          width: 4vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;
        }
      }
    }
    .TX_MAN_HL {
      width: 8.3vw;
      height: 5.2vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 10.5vw;
      top: 2.3vh;
      .row {
        width: 8.3vw;
        height: 2.6vh;
        .col1 {
          width: 4.8vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: right;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: right;
        }
      }
    }
    .a_1 {
      position: absolute;
      left: 9vw;
      top: 3.4vh;
    }
    .x_1 {
      position: absolute;
      left: 1.6vw;
      top: 3.4vh;
    }
    .q_1 {
      position: absolute;
      left: 5.3vw;
      top: 14vh;
    }
  }
  // 下右模型
  .main3 {
    width: 9vw;
    height: 14vh;
    //  background: #e9f7ff;
    background-image: url("~@/assets/images/MFL_脱硫脱硝切图02.png");
    background-size: 100% 100%;
    //  cursor:move;
    border-radius: 5px;
    color: #ffffff;
    position: absolute;
    left: 34.2vw;
    top: 50vh;
    .TX_A_PVL {
      width: 7.3vw;
      height: 5.2vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: -7.2vw;
      top: -2.6vh;
      .row {
        width: 7.3vw;
        height: 2.6vh;
        .col1 {
          width: 4vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;
        }
      }
    }
    .TX_A_SP {
      width: 7.3vw;
      height: 8vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: -7.2vw;
      top: 3.4vh;
      .row {
        width: 7.3vw;
        height: 2.6vh;
        .col1 {
          width: 4vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;
        }
      }
    }
    .TX_MAN {
      width: 7.3vw;
      height: 2.7vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 10.5vw;
      top: -2vh;
      .row {
        width: 7.3vw;
        height: 2.6vh;
        .col1 {
          width: 4vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: center;
        }
      }
    }
    .TX_MAN_HL {
      width: 8vw;
      height: 5.2vh;
      background-color: #001b2860;
      border-radius: 0vh;
      border: solid 0vh #236f8d;
      position: absolute;
      left: 10.5vw;
      top: 2.3vh;
      .row {
        width: 8vw;
        height: 2.6vh;
        .col1 {
          width: 4.7vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #8aeaff;
          text-align: center;
        }
        .col2 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #2fc3e3;
          text-align: center;
        }
        .col3 {
          width: 3.3vw;
          font-family: PingFang-SC-Regular;
          font-size: 0.9vw;
          font-weight: normal;
          font-stretch: normal;
          line-height: 2.6vh;
          letter-spacing: 0vh;
          color: #00ffb4;
          text-align: right;
        }
      }
    }
    .a_1 {
      position: absolute;
      left: 9vw;
      top: 3.4vh;
    }
    .x_1 {
      position: absolute;
      left: 1.6vw;
      top: 3.4vh;
    }
    .q_1 {
      position: absolute;
      left: 5.3vw;
      top: 14vh;
    }
  }

  // 按钮样式
  .bottonx {
    width: 1vw;
    height: 2vh;
    text-align: center;
    line-height: 2vh;
    border: 2px solid rgb(217, 243, 145);
    font-size: 1.7vh;
    // margin-right: 0.1vw;
    // color: #000000;
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .title-hang {
    height: 5vh;
    width: 53vw;
    font-family: MicrosoftYaHei;
    font-size: 1.8vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
    padding-top: 0.5vh;
    // margin-top:vh;
  }
  .title1 {
    width: 8vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    color: #ffffff;
    margin-top: 1vh;
    margin-left: 1vw;
  }
  .title-hang {
    width: 100%;
    height: 6vh;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-size: 3vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #0ef7ff;
  }
  .lable1 {
    font-family: PingFang-SC-Regular;
    font-size: 2.5vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2.5vh;
    letter-spacing: 0vh;
    color: #d5fffe;
    margin-left: 2.5vw;
    margin-top: 1vh;
  }
  .titlelable {
    width: 53vw;
    height: 3vh;
    font-family: PingFang-SC-Regular;
    font-size: 3vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0.3vw;
    color: #d5fffe;
    text-align: center;
    margin-top: 4vh;
    margin-bottom: 2vh;
  }
  .param11-header-icon {
    width: 2vw;
    height: 2vh;
    right: 1vw;
    margin-top: -5vh;
    cursor: pointer;
    margin-left: 50vw;
  }
  .button-top {
    width: 5vw;
    height: 3vh;
    background-color: #008db1;
    text-align: center;
    line-height: 3vh;
    margin-right: 3vw;
  }
}
</style>